import React, { PropsWithChildren } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
    MsalAuthenticationTemplate,
    useMsal,
    MsalAuthenticationResult,
    useAccount,
    IMsalContext,
} from '@azure/msal-react';
import { InteractionType, InteractionRequiredAuthError, BrowserAuthError } from '@azure/msal-browser';
import { getRedirectRequest, getLoginMethod } from '@/common/util/employeeAuthConfig';
import { Spinner } from '../../components/Spinner/Spinner';
import { singletonFetchAuthenticationResult } from './useAuthenticationResult';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { logError } from '@/common/util/logging/writeLog';

const LoginError = (error: MsalAuthenticationResult): JSX.Element => {
    const appInsights = useAppInsightsContext();
    const { instance: instance } = useMsal();
    const account = useAccount();

    logError(appInsights, 'Login Error', { authenticationResult: error });

    let message = 'Error signing in';

    if (error.error?.message?.includes('AADSTS50105')) {
        message = 'Your account does not have access to this application.';
    }

    const loginMethod = getLoginMethod();
    if (error instanceof InteractionRequiredAuthError && loginMethod === InteractionType.Popup) {
        message =
            'Your browser may have blocked a pop-up required for login.  Please disable pop-up blocking for this site and refresh.';
    }

    // If we got an error due to browser issues, try forcing a manual redirect
    if (error instanceof BrowserAuthError) {
        // Need to trigger redirect
        void singletonFetchAuthenticationResult(appInsights, instance, account, true, true);
        return null;
    }

    return (
        <Container className="bodyContent">
            <Row className="error p-3">
                <Col>{message}</Col>
            </Row>
        </Container>
    );
};

const LoginInProgress = (_props: IMsalContext): JSX.Element => {
    // TODO: does this need more?
    return <Spinner />;
};

export const AuthenticatedWrapper = (props: PropsWithChildren): JSX.Element => {
    const loginMethod = getLoginMethod();

    return (
        <MsalAuthenticationTemplate
            interactionType={loginMethod}
            authenticationRequest={getRedirectRequest()}
            errorComponent={LoginError}
            loadingComponent={LoginInProgress}
        >
            {props.children}
        </MsalAuthenticationTemplate>
    );
};
